import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import './footer.scss';

const Footer = () => {
    const data = useStaticQuery(graphql`
     query {
         site {
             siteMetadata {
                 author
             }
         }
     }
    `)
    return (
        <footer className="footer">
            <p>Created by {data.site.siteMetadata.author}, © 2021</p>
        </footer>
    )
}

export default Footer